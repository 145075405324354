import React from 'react';
import { useWhitelabelByIndexHTML } from 'lane-shared/hooks/useWhitelabelByIndexHTML';

import { OnboardingCompany } from 'components/onboarding/Onboarding/OnboardingSteps/OnboardingCompany';

export function OnboardingTestingCompany() {
  const whitelabel = useWhitelabelByIndexHTML();

  return (
    <OnboardingCompany
      buildingFromContext={null}
      errorMessage="onboarding completion error"
      userName="onboarding tester"
      onboardingState="loading"
      whitelabel={whitelabel}
      onComplete={() => {}}
      onClear={() => {}}
      onCancel={() => {}}
    />
  );
}

export default OnboardingTestingCompany;
