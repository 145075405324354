import React, { useState } from 'react';
import { useWorkplaceOnboardingEnabled } from 'lane-shared/hooks/useWorkplaceOnboardingEnabled';
import { Button, Modal, Flex } from 'design-system-web';
import { useTranslation } from 'react-i18next';
import styles from './index.scss';

const TRANSLATION_KEYS = {
  createButton: 'shared.onboarding.cancelAccountCreate.button',
  createModalTitle: 'shared.onboarding.cancelAccountCreate.modal.title',
  setupButton: 'shared.onboarding.cancelAccountSetup.button',
  setupModalTitle: 'shared.onboarding.cancelAccountSetup.modal.title',
  modalDescription: 'shared.onboarding.cancelAccountSetup.modal.description',
  modalConfirm: 'shared.onboarding.cancelAccountSetup.modal.confirmButton',
  modalBack: 'shared.onboarding.backButton',
};

type Props = {
  isLoading?: boolean;
  onSubmit: () => void;
  isInSetup?: boolean;
};

export function CancelAccount({ isLoading, onSubmit, isInSetup }: Props) {
  const { t } = useTranslation();
  const isWorkplaceOnboardingEnabled = useWorkplaceOnboardingEnabled();

  const [isModalOpen, setIsModalOpen] = useState(false);

  if (!isWorkplaceOnboardingEnabled) {
    return null;
  }

  const buttonText = isInSetup
    ? TRANSLATION_KEYS.setupButton
    : TRANSLATION_KEYS.createButton;
  const modalTitle = isInSetup
    ? TRANSLATION_KEYS.setupModalTitle
    : TRANSLATION_KEYS.createModalTitle;

  return (
    <div>
      <Button
        className={styles.button}
        fullWidth
        onClick={() => setIsModalOpen(true)}
        size="large"
        variant="text"
        loading={isLoading}
      >
        {t(buttonText)}
      </Button>
      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        className={styles.modal}
        title={t(modalTitle)}
        actions={
          <Flex className={styles.actionButtons}>
            <Button
              size="large"
              onClick={() => {
                setIsModalOpen(false);
                onSubmit();
              }}
            >
              {t(TRANSLATION_KEYS.modalConfirm)}
            </Button>
            <Button
              size="large"
              variant="secondary"
              onClick={() => {
                setIsModalOpen(false);
              }}
            >
              {t(TRANSLATION_KEYS.modalBack)}
            </Button>
          </Flex>
        }
      >
        <div>{t(TRANSLATION_KEYS.modalDescription)}</div>
      </Modal>
    </div>
  );
}
