import styles from './index.scss';
import { Icon } from 'design-system-web';
import cx from 'classnames';
import { Input, Loading } from 'components';
import ChannelSearchCircleListView from 'components/lane/ChannelSearchCircleListView';
import { ChannelListItem } from 'components/onboarding/components/ChannelListItem';
import { H5 } from 'components/typography';
import { CompanyLocationsSearchResult } from 'lane-shared/contexts/SignUpContext/useCompanyLocationsSearch';
import { CompanyParentSearchResult } from 'lane-shared/contexts/SignUpContext/useCompanyParentSearch';
import {
  FONT_AWESOME_REGULAR,
  ICON_SET_FONTAWESOME,
} from 'lane-shared/helpers/constants/icons';
import { useContinueAsGuest } from 'lane-shared/hooks/useContinueAsGuest';
import { Channel } from 'lane-shared/types/ChannelType';
import React, { useRef, useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';

export type OnboardingCompanyInputProps = {
  data: {
    signupError: Error | null;
    company: Channel | null;
    building: Channel | null;
    parentCompanySearch: string;
    companyParentsResult: CompanyParentSearchResult;
    companyLocationSearch: string;
    parentCompany: Channel | null;
    companyLocationsResult: CompanyLocationsSearchResult;
  };
  handlers: {
    resetSelection: () => void;
    onParentCompanySearchTextChange: (parentCompanySearch: string) => void;
    onParentCompanySelectionChange: (parentCompany: Channel) => void;
    onCompanyLocationSearchTextChange: (companyLocationSearch: string) => void;
    onCompanyBuildingSelectionChange: (
      company: Channel | null,
      building: Channel | null
    ) => void;
  };
};

const TRANSLATION_KEYS = {
  multipleLocations: 'shared.onboarding.company.multipleLocations.title',
  location: 'shared.onboarding.company.location.title',
  companySearchPlaceholder: 'web.onboarding.company.name.search.placeholder',
  searchResultsTitle: 'shared.onboarding.company.name.search.results.title',
};

export function OnboardingCompanyInputLegacy({
  data: {
    company,
    building,
    parentCompanySearch,
    companyParentsResult,
    companyLocationSearch,
    parentCompany,
    companyLocationsResult,
  },
  handlers: {
    resetSelection,
    onParentCompanySearchTextChange,
    onParentCompanySelectionChange,
    onCompanyLocationSearchTextChange,
    onCompanyBuildingSelectionChange,
  },
}: OnboardingCompanyInputProps) {
  const { t } = useTranslation();
  const {
    translationKeys: { locationPlaceholder },
  } = useContinueAsGuest();

  const parentCompanySearchRef = useRef(null);

  useLayoutEffect(() => {
    if (parentCompanySearchRef.current) {
      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      parentCompanySearchRef.current.focus();
    }
  }, []);

  const hasCompanyLocations = companyLocationsResult.locations.length > 0;
  const hasMoreThanOneCompanyLocation =
    companyLocationsResult.locations.length > 1;

  const companyLocationSearchHeader = hasMoreThanOneCompanyLocation
    ? TRANSLATION_KEYS.multipleLocations
    : TRANSLATION_KEYS.location;

  return (
    <div>
      {!parentCompany ? (
        <div>
          <div className={styles.searchContainer}>
            <Input
              ref={parentCompanySearchRef}
              onChange={onParentCompanySearchTextChange}
              value={parentCompanySearch}
              placeholder={t(TRANSLATION_KEYS.companySearchPlaceholder)}
              testId="companyName"
              icon="search"
            />
          </div>
          <div className={styles.results}>
            {companyParentsResult.companies.map((company: Channel, index) => (
              <ChannelSearchCircleListView
                className={styles.companyResult}
                key={company._id || index}
                channel={company}
                onClick={onParentCompanySelectionChange}
                hideDescription
              />
            ))}
            {companyParentsResult.loading && (
              <Loading className={styles.loading} />
            )}
          </div>
        </div>
      ) : (
        <div>
          <div className={styles.selectedCompanyContainer}>
            <ChannelSearchCircleListView
              className={cx([styles.companyResult, styles.selected])}
              key={parentCompany._id}
              channel={parentCompany}
              onClick={() => {
                resetSelection();
              }}
              hideDescription
            />
            <Icon
              name="edit"
              className={styles.checkIcon}
              set={ICON_SET_FONTAWESOME}
              type={FONT_AWESOME_REGULAR}
            />
          </div>
          {!company ? (
            <>
              {hasCompanyLocations && (
                <H5 mb={4}>{t(companyLocationSearchHeader)}</H5>
              )}
              <Input
                className={styles.companyLocationSearchInput}
                onChange={onCompanyLocationSearchTextChange}
                value={companyLocationSearch}
                placeholder={t(locationPlaceholder)}
                testId="buildingLocation"
                icon="search"
                showClear
              />
              <div className={styles.results}>
                {companyLocationsResult.locations.map((location, index) => (
                  <ChannelListItem
                    className={cx([styles.companyResult, styles.selected])}
                    key={(location as any)._id || index}
                    channel={location.channel}
                    onClick={() =>
                      onCompanyBuildingSelectionChange(
                        // location.channel is company
                        location.channel,
                        // location.relatedTo is building
                        location.relatedTo
                      )
                    }
                    showName
                  />
                ))}
                {companyLocationsResult.loading && (
                  <Loading className={styles.loading} />
                )}
              </div>
            </>
          ) : (
            <>
              <H5 className={styles.locationPlaceholder} mb={4}>
                {t(locationPlaceholder)}
              </H5>
              <div className={styles.selectedLocationContainer}>
                <ChannelListItem
                  className={cx([styles.locationResult, styles.selected])}
                  channel={building as Channel}
                  showName
                  onClick={() => {
                    onCompanyBuildingSelectionChange(null, null);
                  }}
                />
                <Icon
                  name="edit"
                  className={styles.checkIcon}
                  set={ICON_SET_FONTAWESOME}
                  type={FONT_AWESOME_REGULAR}
                />
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
}
