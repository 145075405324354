import { config as sharedConfig } from 'lane-shared/config';
import { useHistory } from 'react-router-dom';

export function useAuth0WebRedirectURL() {
  const history = useHistory();
  const { redirectionServiceURL } = sharedConfig.auth0;

  const existingSearchParams = new URLSearchParams(history.location.search);
  const currentChannelId = existingSearchParams.get('channelId');

  const redirectURLSearchParams = new URLSearchParams({
    destination_url: window.location.origin,
  });

  if (currentChannelId) {
    redirectURLSearchParams.set('chid', currentChannelId);
  }

  return `https://${redirectionServiceURL}?${redirectURLSearchParams}`;
}
