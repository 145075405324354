import React from 'react';

import { useTranslation } from 'react-i18next';

import { WhiteLabelType } from 'lane-shared/types/WhiteLabelType';

import { H4 } from 'components/typography';

import { ProfileData, ProfileForm } from './ProfileForm';

import styles from './index.scss';

const TRANSLATION_KEYS = {
  header: 'web.onboarding.userProfile.header',
};

type Props = {
  defaultUserName?: string;
  onContinue: (data: { fullName: string; marketingOptIn: boolean }) => void;
  onCancel: () => void;
  whitelabel: Pick<
    WhiteLabelType,
    | 'termsAndConditionsUrls'
    | 'privacyPolicyUrls'
    | 'complianceContact'
    | 'name'
  >;
};

export const OnboardingProfile = ({
  defaultUserName,
  onContinue,
  whitelabel,
  onCancel,
}: Props) => {
  const { t } = useTranslation();

  const handleSubmit = (data: ProfileData) => {
    onContinue(data);
  };

  return (
    <div className={styles.container}>
      <H4 className={styles.header} mb={6}>
        {t(TRANSLATION_KEYS.header)}
      </H4>
      <ProfileForm
        defaultUserName={defaultUserName}
        onSubmit={handleSubmit}
        whitelabel={whitelabel}
        onCancel={onCancel}
      />
    </div>
  );
};
