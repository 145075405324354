import { OnboardingBuildingInput } from '../OnboardingBuildingInput';
import { ErrorMessage } from 'components';
import { CancelAccount } from '../../components/CancelAccount';
import styles from './index.scss';
import { Button } from 'design-system-web';
import { H5 } from 'components/typography';
import { useContinueAsGuest } from 'lane-shared/hooks/useContinueAsGuest';
import { useOnboardingAnalytics } from 'lane-shared/hooks/analytics';
import { Channel } from 'lane-shared/types/ChannelType';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useWorkplaceOnboardingEnabled } from 'lane-shared/hooks/useWorkplaceOnboardingEnabled';
import { useOnboardingBuildings } from 'lane-shared/contexts/SignUpContext/useOnboardingBuildings';
import { ActiveChannelTypeEnum } from 'constants-channel';

const TRANSLATION_KEYS = {
  privateBuildingError: 'web.onboarding.building.privateAccess.error',
  serverError: 'web.onboarding.building.server.error',
  createAccountButton: 'shared.onboarding.building.createAccountButton',
  continueButton: 'shared.onboarding.building.continueButton',
  header: 'shared.onboarding.building.location.title',
};

type Props = {
  onboardingState: 'loading' | 'idle' | 'error';
  userName: string;
  onComplete: (building: Channel, userName: string) => void;
  onContinue: (building: Channel) => void;
  onClear: () => void;
  onCancel: () => void;
  presumedEmailDomain?: string;
  invitedChannel?: Channel;
};

export function OnboardingSelectBuilding({
  onboardingState,
  userName,
  onComplete,
  onContinue,
  onClear,
  onCancel,
  invitedChannel,
  presumedEmailDomain,
}: Props) {
  const { t } = useTranslation();
  const isWorkplaceOnboardingEnabled = useWorkplaceOnboardingEnabled();
  const { onboardingTracker } = useOnboardingAnalytics();

  const [searchText, setSearchText] = useState('');
  const [building, setBuilding] = useState<Channel | null>(null);

  const {
    buildings,
    isInitialLoading,
    isFetchingMore,
    hasNextPage,
    fetchMore,
  } = useOnboardingBuildings({
    query: searchText,
    presumedEmailDomain,
  });

  const {
    translationKeys: { locationPrompt, locationPlaceholder },
  } = useContinueAsGuest();

  if (
    invitedChannel &&
    building !== invitedChannel &&
    invitedChannel?.type === ActiveChannelTypeEnum.Property
  ) {
    setBuilding(invitedChannel);
  }

  const handleBuildingSearchChange = (text: string) => {
    setSearchText(text);
  };

  const handleBuildingSelectionChange = (buildingObj: Channel | null) => {
    setBuilding(buildingObj);

    if (buildingObj) {
      onboardingTracker.Select.Building({ buildingId: buildingObj?._id });
    }

    if (buildingObj === null) {
      setBuilding(null);
      onClear();
      onboardingTracker.Remove.Building({ buildingId: building?._id! });
    }
  };

  const handleCreateAccount = () => {
    if (building) {
      onComplete(building, userName);
      onboardingTracker.Create.Account({ buildingId: building?._id });
    }
  };

  const privateBuildingError = building?.inviteOnly
    ? new Error(t(TRANSLATION_KEYS.privateBuildingError))
    : null;

  const header = building ? locationPlaceholder : locationPrompt;

  return (
    <div className={styles.container}>
      {privateBuildingError && <ErrorMessage error={privateBuildingError} />}
      <H5>
        {isWorkplaceOnboardingEnabled ? t(TRANSLATION_KEYS.header) : t(header)}
      </H5>
      <div className={styles.searchContainer}>
        <OnboardingBuildingInput
          data={{
            building,
            buildingSearch: searchText,
            buildings,
            whiteLabelName: 'whitelabelName',
          }}
          config={{
            isLoading: isInitialLoading,
            isFetchingMore,
            hasNextPage,
            fetchMore,
          }}
          handlers={{
            onBuildingSearchTextChange: handleBuildingSearchChange,
            onBuildingSelectionChange: handleBuildingSelectionChange,
          }}
        />
        {isWorkplaceOnboardingEnabled && (
          <Button
            className={styles.button}
            fullWidth
            onClick={() => {
              if (building) {
                onContinue(building);
              }
            }}
            size="large"
            type="submit"
            loading={onboardingState === 'loading'}
            disabled={building === null}
          >
            {t(TRANSLATION_KEYS.continueButton)}
          </Button>
        )}
        {!isWorkplaceOnboardingEnabled && building && (
          <Button
            className={styles.button}
            fullWidth
            onClick={handleCreateAccount}
            size="large"
            type="submit"
            loading={onboardingState === 'loading'}
          >
            {t(TRANSLATION_KEYS.createAccountButton)}
          </Button>
        )}
        <CancelAccount
          isLoading={onboardingState === 'loading'}
          onSubmit={onCancel}
          isInSetup
        />
      </div>
    </div>
  );
}
