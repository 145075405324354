import { ChannelListItem } from '../../../../components/ChannelListItem';
import React from 'react';
import { Channel } from 'lane-shared/types/ChannelType';
import { AddressType } from 'packages/lane-shared/types/AddressType';
import { useWorkplaceOnboardingEnabled } from 'lane-shared/hooks/useWorkplaceOnboardingEnabled';

type Building = {
  id: string;
  name: string;
  thumbnailUrl: string | null;
  address: AddressType;
  inviteOnly: boolean;
};

type Edge = {
  cursor: string;
  node: Building;
};

type ChannelListItemWrapperProps = {
  building: Edge;
  className?: string;
  style?: React.CSSProperties;
  onClick: (channel: Channel) => void;
  showName?: boolean;
  isSelected?: boolean;
};

export function ChannelListItemWrapper({
  building,
  className,
  style,
  onClick,
  isSelected,
}: ChannelListItemWrapperProps) {
  const isWorkplaceOnboardingEnabled = useWorkplaceOnboardingEnabled();
  const channel = convertBuildingEdgeToChannelType(building);

  return (
    <ChannelListItem
      className={className}
      style={style}
      showName
      channel={channel}
      onClick={onClick}
      showCompanyIcon={isWorkplaceOnboardingEnabled}
      isSelected={isWorkplaceOnboardingEnabled && isSelected}
    />
  );
}

export function convertBuildingEdgeToChannelType(building: Edge): Channel {
  return {
    _id: building.node.id,
    profile: {
      name: building.node.name,
      logo: building.node.thumbnailUrl,
    },
    address: building.node.address,
    inviteOnly: building.node.inviteOnly,
  } as Channel;
}
