import React, { ReactNode, useContext } from 'react';

import { FeatureFlag } from 'constants-flags';
import { useFlag } from 'lane-shared/hooks';

import { ANALYTIC_KEYS } from 'constants-analytics';

import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
  useLocation,
} from 'react-router-dom';

import { AppContext, UserDataContext } from 'lane-shared/contexts';
import { useOnboardingContext } from 'lane-shared/contexts/OnboardingContext/useOnboardingContext';
import { Channel } from 'lane-shared/types/ChannelType';
import { useSimpleTrack } from 'lane-shared/hooks/useSimpleTrack';

import { OnboardingBuildingLegacy } from './OnboardingBuildingLegacy';
import { OnboardingBuilding } from './OnboardingBuilding';
import { OnboardingCompanyLegacy } from './OnboardingCompanyLegacy';
import { OnboardingCompany } from './OnboardingCompany';
import { OnboardingProfile } from './OnboardingProfile';
import { OnboardingWithInvite } from './OnboardingWithInvite';
import { useQuery } from '@apollo/client';
import { getChannel } from 'lane-shared/graphql/query';
import { useWorkplaceOnboardingEnabled } from 'lane-shared/hooks/useWorkplaceOnboardingEnabled';

const renderOnboardingBuildingOrInvite = ({
  OnboardingBuilding,
  OnboardingWithInvite,
  Redirect,
}: {
  OnboardingBuilding?: ReactNode;
  OnboardingWithInvite?: ReactNode;
  Redirect: ReactNode;
}) => {
  if (OnboardingWithInvite) {
    return OnboardingWithInvite;
  }

  if (OnboardingBuilding) {
    return OnboardingBuilding;
  }

  return Redirect;
};

export const OnboardingSteps = () => {
  const { path } = useRouteMatch();
  const history = useHistory();
  const { whitelabel } = useContext(AppContext);
  const { user } = useContext(UserDataContext);
  const simpleTrack = useSimpleTrack();
  const location = useLocation();

  const presumedEmailDomain = user?.profile.email.split('@')[1].toLowerCase();

  const enhancedOnboardingFeatureFlag = useFlag(
    FeatureFlag.EnhancedOnboardingChannelSearch,
    false
  );
  const isWorkplaceOnboardingEnabled = useWorkplaceOnboardingEnabled();

  const {
    data: { building, company, profile },
    errors,
    onboardingState,
    handlers,
  } = useOnboardingContext();

  const invitedChannelId = new URLSearchParams(location.search).get(
    'channelId'
  );

  const userFullName = profile.fullName || user?.profile.name;

  const { data: invitedChannel } = useQuery(getChannel, {
    variables: { id: invitedChannelId },
    skip: !invitedChannelId,
  });

  const handleContinue = (pathname: string) => {
    const currentQueryParameters = new URLSearchParams(history.location.search);
    const newQueryParameters = new URLSearchParams();

    const currentContentPath = currentQueryParameters.get('contentPath');
    const currentChannelId = currentQueryParameters.get('channelId');

    if (currentContentPath) {
      newQueryParameters.set('contentPath', currentContentPath);
    }

    if (currentChannelId) {
      newQueryParameters.set('channelId', currentChannelId);
    }

    history.push({
      pathname,
      search: newQueryParameters.toString(),
    });
  };

  const handleProfileContinue = (data: {
    fullName: string;
    marketingOptIn: boolean;
  }) => {
    simpleTrack(ANALYTIC_KEYS.ANALYTIC_USER_ONBOARDING_PROFILE_CONTINUE, {
      optIn: data.marketingOptIn,
    });
    handlers.updateProfile(data);

    const url =
      building || isWorkplaceOnboardingEnabled
        ? `${path}/building`
        : `${path}/company`;

    handleContinue(url);
  };

  const handleBuildingContinue = (building: Channel) => {
    simpleTrack(ANALYTIC_KEYS.ANALYTIC_USER_ONBOARDING_BUILDING_CONTINUE, {
      buildingId: building._id,
    });
    handlers.updateBuilding(building);

    handleContinue(`${path}/company`);
  };

  const handleBuildingComplete = (building: Channel, fullName: string) => {
    simpleTrack(
      ANALYTIC_KEYS.ANALYTIC_USER_ONBOARDING_BUILDING_CREATE_ACCOUNT,
      {
        buildingId: building._id,
      }
    );

    const accountData = {
      fullName,
      marketingOptIn: profile.isMarketingOptInSelected,
      building,
    };

    handlers.completeOnboarding(accountData);
  };

  const handleCompanyComplete = (
    company: Channel,
    building: Channel,
    fullName: string
  ) => {
    simpleTrack('onboarding.building.createAccount', {
      buildingId: building._id,
      companyId: company._id,
    });

    const accountData = {
      fullName,
      marketingOptIn: profile.isMarketingOptInSelected,
      building,
      company,
    };

    handlers.completeOnboarding(accountData);
  };

  const handleBuildingInviteComplete = (
    building: Channel,
    fullName: string,
    company?: Channel
  ) => {
    simpleTrack('onboarding.building.createAccount', {
      buildingId: building._id,
      companyId: company?._id,
    });

    const accountData = {
      fullName,
      marketingOptIn: profile.isMarketingOptInSelected,
      building,
      company,
    };

    handlers.completeOnboarding(accountData);
  };

  const handleOnChange = () => {
    handlers.onBuildingOrCompanySelectionChange();
  };

  const handleOnCancelOnboarding = () => {
    handlers.cancelOnboarding();
  };

  const onboardingBuildingRender = enhancedOnboardingFeatureFlag ? (
    <OnboardingBuilding
      errorMessage={errors.completionError}
      userName={userFullName || ''}
      onboardingState={onboardingState}
      onComplete={handleBuildingComplete}
      onContinue={handleBuildingContinue}
      onClear={handleOnChange}
      onCancel={handleOnCancelOnboarding}
      invitedChannel={invitedChannel && invitedChannel.channel}
      presumedEmailDomain={presumedEmailDomain}
    />
  ) : (
    <OnboardingBuildingLegacy
      errorMessage={errors.completionError}
      userName={userFullName || ''}
      onboardingState={onboardingState}
      onComplete={handleBuildingComplete}
      onClear={handleOnChange}
    />
  );

  const onboardingCompanyWithBuildingRender = enhancedOnboardingFeatureFlag ? (
    <OnboardingCompany
      errorMessage={errors.completionError}
      userName={userFullName || ''}
      onboardingState={onboardingState}
      whitelabel={whitelabel}
      onComplete={handleCompanyComplete}
      onClear={handleOnChange}
      onCancel={handleOnCancelOnboarding}
      invitedChannel={invitedChannel && invitedChannel.channel}
      buildingFromContext={building ?? null}
      presumedEmailDomain={presumedEmailDomain}
    />
  ) : (
    <OnboardingCompanyLegacy
      errorMessage={errors.completionError}
      userName={userFullName || ''}
      onboardingState={onboardingState}
      whitelabel={whitelabel}
      onComplete={handleCompanyComplete}
      onClear={handleOnChange}
    />
  );

  const onboardingCompanyRender =
    isWorkplaceOnboardingEnabled && !building?._id
      ? onboardingBuildingRender
      : onboardingCompanyWithBuildingRender;

  return (
    <Switch>
      <Route exact path={`${path}/profile`}>
        <OnboardingProfile
          defaultUserName={profile.fullName}
          onContinue={handleProfileContinue}
          whitelabel={whitelabel}
          onCancel={handleOnCancelOnboarding}
        />
      </Route>
      <Route exact path={`${path}/building`}>
        {renderOnboardingBuildingOrInvite({
          OnboardingWithInvite:
            profile.fullName && building ? (
              <OnboardingWithInvite
                building={building}
                company={company}
                userName={profile.fullName}
                onboardingState={onboardingState}
                errorMessage={errors.completionError}
                onComplete={handleBuildingInviteComplete}
                onClear={handleOnChange}
              />
            ) : undefined,
          OnboardingBuilding: userFullName
            ? onboardingBuildingRender
            : undefined,
          Redirect: <Redirect to={`${path}/profile`} />,
        })}
      </Route>
      <Route exact path={`${path}/company`}>
        {userFullName ? (
          onboardingCompanyRender
        ) : (
          <Redirect to={`${path}/profile`} />
        )}
      </Route>
    </Switch>
  );
};
