import React from 'react';
import ChannelSearchCircleListView from 'components/lane/ChannelSearchCircleListView';
import { Channel } from 'lane-shared/types/ChannelType';

type Company = {
  id: string;
  name: string;
  thumbnailUrl: string | null;
  inviteOnly: boolean;
};

type Edge = {
  cursor: string;
  node: Company;
};

type ChannelSearchCircleListWrapperProps = {
  company: Edge;
  className?: string;
  style?: React.CSSProperties;
  onClick: (channel: Channel) => void;
  hideDescription?: boolean;
};

export function ChannelSearchCircleListWrapper({
  company,
  className,
  style,
  onClick,
  hideDescription = false,
}: ChannelSearchCircleListWrapperProps) {
  const channel = {
    _id: company.node.id, // Mapping `id` to `_id`
    profile: {
      name: company.node.name,
      logo: company.node.thumbnailUrl,
    },
    // added for failing test case
    inviteOnly: company.node.inviteOnly,
  };

  return (
    <ChannelSearchCircleListView
      className={className}
      style={style}
      channel={channel}
      onClick={onClick}
      hideDescription={hideDescription}
    />
  );
}
